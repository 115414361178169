<style scoped> 
    .footbar{width:100%; height:160px;  font-size: 12px; color:#666666; text-align: center; background:#fff; padding:1px;}
    .footbar-row{display: block; text-align: center;}
    .underline{text-decoration: none;}
    .underline:hover{text-decoration: underline;}
</style>
<template> 
    <div class='footbar' v-cloak>
        <div class='footbar-row mt30'>
            <!-- 网站地图 ┃ 联系我们 ┃ 网站声明 ┃ 隐私声明 ┃ 使用帮助 ┃ 往期回顾 ┃ 访问统计 主办单位：江苏省招标投标协会 运行维护：江苏迅招信息科技有限公司 苏ICP备14033582号 未经许可，任何单位和个人不得在任何媒体上擅自转载和引用本网站中“招标公告”与“中标公示”中的内容 -->
            <span class='pointer color-40' @click='sitemap'>网站地图 </span>
            <span class='color-66'>|</span>
            <span class='pointer color-40'  @click='contact'>联系我们  </span>
           
        </div>
        <div class="footbar-row mt30">
            主办单位：江苏省招标投标协会
        </div>
        <div class="footbar-row mt5 " >
            <span @click='to_xzzbtb' class='pointer underline'>
                技术支持：江苏迅招信息科技有限公司  
            </span>
            
            <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备14033582号-1</a>
        </div>
        <div class="footbar-row mt5">
            未经许可，任何单位和个人不得在任何媒体上擅自转载和引用本网站中“招标公告”与“中标公示”中的内容
        </div>
    </div>
</template>
<script>
    import tools from '@/api/tools.js';
    export default {
        data(){
            return {
            }
        }
        ,mounted(){
        }
        ,methods:{
            sitemap(){
                var url = './10_1.html';
                location.href = url;
            } 
            ,contact (){
                var url = './11_1.html';
                location.href = url;
            } 
            ,to_xzzbtb(){
                var url = 'https://www.xzzbtb.cn';
                location.href = url;
            }
        }
    }
</script>