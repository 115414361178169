var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footbar" }, [
    _c("div", { staticClass: "footbar-row mt30" }, [
      _c(
        "span",
        { staticClass: "pointer color-40", on: { click: _vm.sitemap } },
        [_vm._v("网站地图 ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "color-66" }, [_vm._v("|")]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "pointer color-40", on: { click: _vm.contact } },
        [_vm._v("联系我们  ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footbar-row mt30" }, [
      _vm._v("\n        主办单位：江苏省招标投标协会\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footbar-row mt5" }, [
      _c(
        "span",
        { staticClass: "pointer underline", on: { click: _vm.to_xzzbtb } },
        [_vm._v("\n            技术支持：江苏迅招信息科技有限公司  \n        ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
        [_vm._v("苏ICP备14033582号-1")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footbar-row mt5" }, [
      _vm._v(
        "\n        未经许可，任何单位和个人不得在任何媒体上擅自转载和引用本网站中“招标公告”与“中标公示”中的内容\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }