import tools from '@/api/tools.js';

import headbar from "@/component/headbar.vue"; 
import footbar from "@/component/footbar.vue"; 
Vue.component('headbar',headbar);
Vue.component('footbar',footbar);
var num = -1;
var list2list = function(list){
    var arr = [];
    var temp = [];
    var list = list;
    var fn = function fn(){
        if(
            list.length == 0
        ){
            if(
                temp.length != 0
            ){
                arr.push(temp);
                temp = [];
            }
            return;
        }
        var item = list.shift();
        temp.push(item);
        if(
            temp.length == 8
        ){
            arr.push(temp);
            temp = [];
        }
        fn();
    }
    fn();
    return arr;
}

function flow(wrap){
	var vx = 2;
	var vy = 2;
	var w = wrap.offsetWidth;
	var h = wrap.offsetHeight;
	var bounce = -1;
	var pause = false;
	wrap.onmouseenter = function(){
		pause = true;
	}
	wrap.onmouseleave = function(){
		pause = false;
	}
	var poll = function poll(){
		setTimeout(poll,20);
		if(pause)return;
		var l = wrap.offsetLeft;
		var t = wrap.offsetTop;
		wrap.style.left = l + vx + 'px';
		wrap.style.top = t + vy + 'px';
		var t_min = 0;
		var t_max = window.innerHeight;
		var l_min = 0;
		var l_max = window.innerWidth;
		if( 
			l < l_min || (l+w) > l_max 
		){
			vx *= bounce;
		}
		if(
			t < t_min || (t+h) > t_max
		){
			vy *= bounce;
		}
		wrap.style.left = l + vx + 'px';
        wrap.style.top = t + vy + 'px';
	}();
}

var vm = new Vue({
    el : '.root'
    ,data:{
        bids_list:[]
        ,notice_list:[]
        ,job_news_list:[]
        ,business_info_list:[]
        ,rule_list:[]
        ,project_info_list:[]
        ,study_list : []
        ,banner_list:[]
        ,alarm_list: []
        ,ad_list:[]
        ,tabs:[
            {name:'招标公告',active:true}
            ,{name:'中标候选人公示',active:false}
            ,{name:'中标结果公示',active:false}
        ]
        ,cur_tab_index:0
        ,cur_typeid : 36
        ,tabs_fn:{
            0:"get_bids" 
            ,1 : "get_candidate"
            ,2 : "get_bids_result"
        }
        ,tabs_type : {
            0:"bids"
            ,1:"candidate"
            ,2:"result"
        }
        ,links:[]
        ,swiper_pages : []
        ,cur_banner:{title:''}
        ,member_list:[]
        ,pause : false
        ,has_flow : true
        ,has_flow1 : true
    }
    ,mounted : async function(){
        this.get_ad();
        this.get_bids();    //取轮播图下面那块
        this.get_job_news();
        this.get_business_info();
        this.get_rule();
        this.get_study();
        this.get_friendlinks();
        this.get_project();
        this.get_alarm();
        // this.set_member_swiper();
        await this.get_banner();
        this.swiper();
        await this.get_notice();  //取左上角通知公告
        await this.get_user_style(); //会员风采
        this.start_marquee();
        this.light_slider();
        this.flow();
    }
    ,methods:{
        get_bids : async function(){
            try{
                var query = {
                    PageSize:11
                    ,CurrentPage : 1
                    ,only : 1
                }
                // layer.load(1);
                var res = await tools.ajax({
                    url : call.DataSyncApi_HomePageTenderQualifyBulletin 
                    ,data:query
                    ,method:"get"
                });
                // layer.closeAll();
                res = res[0];
                var list = res.data || [];
                this.bids_list = [];
                for(var i=0; i<list.length; i++){
                    var el = list[i];
                    var map = {};
                    map.id = el.bulletinid;
                    map.name = el.bulletinname;
                    map.time = this.wash_time(el.createtime);
                    map.typeid= 36;
                    this.bids_list.push(map);
                }
            }catch(err){
                throw(err);
            }
            
        }
        ,download_ad(){
            window.open('./assets/建研〔2021〕259号 2021.12.21-24南京城市更新.docx')
        }
        ,flow(){
            setTimeout(()=>{
                flow(document.querySelector('.flow'))
                // flow(document.querySelector('.flow1'))
            });
        }
        ,get_user_style : async function(){
            var query = {
                currentPage: 1,
                PageSize: 30
            };
            var res = await tools.ajax({
                url : call.AssociationApi_findByPage + util.map2query(query)
            })
            res = res[0];
            var list = res.data.data || [];
            this.member_list = list2list(list);
        }
        ,get_ad : async function(){
            var query = {
                currentPage: 1,
                PageSize: 10
            };
            var res = await tools.ajax({
                url : call.AssociationApi_Advertisements + util.map2query(query)
            })
            res = res[0];
            this.ad_list = res.data.data || [];
        }
        ,to_ad(e,el){
            location.href = el.url;
        }
        ,has_news_tips(t,type){
            var t = t;
            if(
                type == 1
            ){
                t = this.get_date(t);
            }
            t = t.split('-');
            var o = {};
            o.y = +t.shift();
            o.m = +t.shift();
            o.d = +t.shift();
            var d = Date.now();
            d = util.get_ymd(d);
            for(var k in d){
                if(
                    d[k] != o[k]
                ){
                    return false;
                }
            }
            return true;
        }
        ,set_member_swiper(){
            var list = [
                {img:'./assets/img/m1.png',url:''}
                ,{img:'./assets/img/m2.png',url:''}
                ,{img:'./assets/img/m3.png',url:'http://www.ppmfhwm.com/about-company.php'}
                ,{img:'./assets/img/m4.png',url:'http://www.jshyzb.com/WebMain/Main/'}
                ,{img:'./assets/img/m5.png',url:''}
                ,{img:'./assets/img/m6.png',url:''}
                ,{img:'./assets/img/m7.png',url:''}
                ,{img:'./assets/img/m8.png',url:''}
                ,{img:'./assets/img/m9.png',url:''}
                ,{img:'./assets/img/m10.png',url:''}
                ,{img:'./assets/img/m11.png',url:''}
            ];
            this.member_list = list2list(list);
        }
        ,to_member_website(e,el){
            var url = el.url;
            if(
                url
            ){
                location.href = url;
            }
            
        }
        ,get_date(t){
            if(
                t
            ){
                var pos = t.indexOf(' ');
                return t.slice(0,pos);
            }else{
                return  '';
            }
            
        }
        ,light_slider(){
            setTimeout(()=>{
                $("#light-slider").bxSlider({
                    infiniteLoop : true
                    ,pager : false
                    ,controls : false
                    ,auto : true
                });
            })
        }

        ,to_notice_pub(){
            var url = 'https://www.xzzbtb.cn/';
            location.href = url;
        }
        
        ,get_banner : async function(){
            var res = await tools.ajax({
                url  : call.AssociationApi_Banners
            })
            res = res[0];
            this.banner_list = res.data || [];
            // var imgs = ['./assets/img/2.jpg','./assets/img/3.jpg','./assets/img/4.jpg','./assets/img/5.jpg','./assets/img/6.jpg','./assets/img/7.jpg','./assets/img/8.jpg','./assets/img/9.jpg'];
            // for(var i=0; i<imgs.length; i++){
            //     var img = imgs[i];
            //     var map = {
            //         content: ''
            //         ,contentId: ''
            //         ,desc: ''
            //         ,id: ''
            //         ,imageUrl: img
            //         ,issueTime: ''
            //         ,stick: ''
            //         ,title: ''
            //         ,typeID:''
            //         ,typeName: ''
            //         ,url: ''
            //         ,viewCount: ''
            //     }
            //     this.banner_list.push(map);
            // }
            
            
        }
        ,start_marquee(){
            setTimeout(()=>{
                $('.news-content').marquee({
                    //duration in milliseconds of the marquee
                    duration: 15000,
                    //gap in pixels between the tickers
                    gap: 50,
                    //time in milliseconds before the marquee will start animating
                    delayBeforeStart: 0,
                    //'left' or 'right'
                    direction: 'left',
                    //true or false - should the marquee be duplicated to show an effect of continues flow
                    duplicated: true
                });
                
            })
        }
        ,active_swiper(e,index,el){
            for(var i=0; i<this.swiper_pages.length; i++){
                var item = this.swiper_pages[i];
                item.active = false;
            }
            el.active = true;
            $('.swiper_item').hide();
            $('.swiper_item').eq(index).fadeIn();
            this.cur_banner = this.banner_list[index];
            num = index;
        }   
        ,reset_swiper_pages (){
            for(var i=0; i<this.swiper_pages.length; i++){
                var el = this.swiper_pages[i];
                el.active = false;
            }
        }
        ,set_pause(e,state){
            this.pause = state;
        }
        ,swiper(){
            var me = this;
            setTimeout(()=>{
                var length = me.banner_list.length;
                for(var i=0; i<length; i++){
                    var map = {
                        active : false
                    }
                    me.swiper_pages.push(map);
                }
                
                var fn = function fn(){
                    setTimeout(fn,3000);
                    if(
                        me.pause
                    ){
                        return;
                    }
                    num += 1;
                    $('.swiper_item').hide();
                    var index = num % length;
                    $('.swiper_item').eq(index).fadeIn();
                    me.reset_swiper_pages();
                    me.swiper_pages[index].active = true;
                    me.cur_banner = me.banner_list[index];
                    if(
                        length == 1
                    ){
                        me.pause = true;
                    }
                }
                fn();
            })
        }
        ,get_friendlinks : async function(){
            var query = {
                pageSize:100
            }
            var res = await tools.ajax({
                url : call.AssociationApi_FriendshipLink 
                ,params:query
            })
            res = res[0];
            this.links = res.data.data || [];
        }
        ,to_links(e,el){
            var url = el.url;
            location.href = url;
        }
        ,get_notice:async function(){
            var query = {
                currentpage : 1
                ,pageSize : 4
                ,typeId : 16
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.notice_list = res.data.data || [];
        }
        ,get_project:async function(){
            var query = {
                currentpage : 1
                ,pageSize : 5
                ,typeId : 19
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.project_info_list = res.data.data || [];
        }
        ,get_alarm:async function(){
            var query = {
                currentpage : 1
                ,pageSize : 6
                ,typeId : 23
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.alarm_list = res.data.data || [];
        }
        ,get_study:async function(){
            var query = {
                currentpage : 1
                ,pageSize : 6
                ,typeId : 22
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.study_list = res.data.data || [];
        }
        ,get_rule:async function(){
            var query = {
                currentpage : 1
                ,pageSize :5
                ,typeId : 18
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.rule_list = res.data.data || [];
        }
        ,get_business_info:async function(){
            var query = {
                currentpage : 1
                ,pageSize :3
                ,typeId : 20
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.business_info_list = res.data.data || [];
        }
        ,get_job_news:async function(){
            var query = {
                currentpage : 1
                ,pageSize : 6
                ,typeId : 17
            }
            var res = await tools.ajax({
                url : call.AssociationApi_webContents
                ,method : 'get'
                ,data : query
            });
            res = res[0];
            this.job_news_list = res.data.data || [];
        }
        ,to_list(e,typeid){
            var query = {
                typeid:typeid
                ,page : 1
            }
            location.href = './list.html' + util.map2query(query);
        }
        ,to_detail(e,el){
            var id = el.id;
            var url = `./content.html?id=${id}`;
            location.href = url;
        }
        ,to_banner_detail(e){
            if(
                !this.cur_banner.title
            ){
                return;
            }
            var id = this.cur_banner.contentId;
            var url = `./content.html?id=${id}`;
            location.href = url;
        }
        ,to_5_1(){
            var query = {
                typeid :this.cur_typeid
            }
            location.href = './5_1.html' + util.map2query(query);
        }
        ,to_bids_detail(e,el){
            var type = this.tabs_type[this.cur_tab_index];
            var query = {
                id : el.id
                ,typeid : el.typeid
                ,type:type
            }
            var url = `./5_2.html` + util.map2uri(query);
            location.href = url;
        }
        ,to_7_1(e,typeid){
            var query = {
                typeid : typeid
            }
            location.href = './7_1.html' + util.map2query(query);
        }
        ,to_0_2(){
            location.href='./0_2.html';
        }
        ,get_candidate : async function(){
            var query = {
                PageSize:11
                ,CurrentPage : 1
                ,only : 1
            }
            // layer.load(1);
            var res = await tools.ajax({
                url : call.DataSyncApi_HomeWinCandidateBulletin 
                ,data:query
                ,method:"get"
            });
            // layer.closeAll();
            res = res[0];
            var list = res.data.data || [];
            this.bids_list = [];
            for(var i=0; i<list.length; i++){
                var el = list[i];
                var map = {};
                map.id = el.id;
                map.name = el.publicityName;
                map.time = util.wash_date(el.create_time);
                map.typeid=37;
                this.bids_list.push(map);
            }
        }
        ,get_bids_result : async function(){
            var query = {
                PageSize:11
                ,CurrentPage : 1
                ,only : 1
            }
            // layer.load(1);
            var res = await tools.ajax({
                url : call.DataSyncApi_HomeWinBidBulletin 
                ,data:query
                ,method:"get"
            });
            // layer.closeAll();
            res = res[0];
            var list = res.data.data || [];
            this.bids_list = [];
            for(var i=0; i<list.length; i++){
                var el = list[i];
                var map = {};
                map.id = el.id;
                map.name = el.bulletinName;
                map.time = util.wash_date(el.create_time);
                map.typeid = 38;
                this.bids_list.push(map);
            }
        }
        ,wash_time(t){
            var t = ''+t;
            return t.slice(0,t.indexOf(' '));
        }
        ,ative_tab(e,el,index){
            var mapping = {
                "资格预审公告":35
                ,"招标公告" : 36
                ,"中标候选人公示" : 37
                ,"中标结果公示" : 38
            }
            this.cur_tab_index = index;
            this.cur_typeid = mapping[el.name];
            for(var i=0; i<this.tabs.length; i++){
                var tab = this.tabs[i];
                tab.active = false;
            }
            el.active = true;
            var key = this.tabs_fn[index];
            this[key]();
        }
    }
    
})