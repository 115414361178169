<style scoped>
    .headbar{background: #F8F8F8;}
    .head-bg{width:100%; height:220px; background:url('/assets/img/head_bg.png'); background-repeat: no-repeat; background-size:cover;}
    .head-logo{width:86px; height:auto;}
    .head-logo-text{width:410px;}
    .nav{background:#3F5092; height:50px; text-align: center;}
    .nav-wrap{width:1100px; text-align:center; font-size: 0; display: inline-block; height:100%;}
    .nav-item{width: 91.66666px; font-size: 14px; height:32px; line-height: 32px; color:#fff; display:inline-block; vertical-align: middle; text-align: center; cursor: pointer; position: relative;}
    .nav-active{background:#fff; color:#3F5092; border-radius: 20px;}
    .nav-item:hover{background:#fff; color:#3F5092; border-radius: 20px;}
    .subnav{width:100%; background:#fff; border-radius: 20px; position: absolute; left:0; top:0; z-index: 9; box-shadow: 0 0 6px rgba(82,98,160,0.2); overflow: hidden;}
    .subnav-item{width: 91.66666px; font-size: 12px; height:40px; line-height:40px; overflow: hidden; text-overflow: ellipsis; color:#666;  text-align: center; cursor: pointer; background:#fff;}
    .subnav-item:hover{background:#EFEFEF; color:#3F5092;}
    .subnav-item:first-of-type{font-size: 14px !important; height:32px; line-height:32px; }
</style>
<template>
    <div class='headbar' v-cloak>
        <div class="head-bg text-center">
            <div class="inline-block w-1100 v-middle h100 text-left">
                <img src="@/assets/img/logo.png" alt="" class='v-mid head-logo'>
                <img src="@/assets/img/logo_text.png" alt="" class='v-mid head-logo-text ml30'>
            </div>
        </div>
        <div class="nav">
            <div class='nav-wrap v-middle'>
                <div class="nav-item" v-for='(el,index) in nav' :class='{"nav-active":el.active}' @click='active($event,el,index)' @mouseenter="active_subnav($event,el,index)" @mouseleave="unactive_subnav($event,el,index)">
                    {{el.label}}
                    <div class='subnav' v-show='el.has_subnav'>
                        <div class="subnav-item" v-for='(item,k) in el.children' @click.stop='active($event,item,k)'>
                            {{item.label}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    var find = function fn(list,id,index){
        var rs = -1
        for(var i=0; i<list.length; i++){
            if(
                rs !=-1
            ){
                return rs;
            }
            var el = list[i];
            if(
                el.value == id
            ){
                if(
                    !el.parent_id
                ){
                    rs = i;
                    break;
                }else{
                    rs = index;
                    break;
                }
            }
            var children = el.children;
            rs = fn(children,id,i);
        }
        return rs;
    }
    import tools from '@/api/tools.js';
    export default {
        data(){
            return {
                nav:[
                    {label:'首页',vaule:'-1',url:'./0.html',active:false,children:[]},
                    {
                        "value":15,
                        "label":"关于协会",
                        url:'./1_1.html?typeid=26',
                        active:false,
                        has_subnav:false,
                        "children":[
                            { "value":26, "label":"关于协会",url:'./1_1.html?typeid=26',active:false,parent_id:15,children:[] },
                            { "value":26, "label":"协会简介",url:'./1_1.html?typeid=26',active:false,parent_id:15,children:[] },
                            { "value":27, "label":"协会章程",url:'./1_1.html?typeid=27',active:false ,parent_id:15,children:[]},
                            { "value":28, "label":"协会领导",url:'./1_1.html?typeid=28',active:false ,parent_id:15,children:[]},
                            // { "value":29, "label":"协会组织机构图",url:'./1_1.html?typeid=29',active:false ,parent_id:15,children:[]},
                            // { "value":30, "label":"常务理事名单",url:'./1_1.html?typeid=30',active:false,parent_id:15,children:[]},
                            { "value":31, "label":"理事名单",url:'./1_1.html?typeid=31',active:false,parent_id:15,children:[]},
                            { "value":32, "label":"会员名单",url:'./1_1.html?typeid=32',active:false ,parent_id:15,children:[]},
                            {  "value":33, "label":"协会文件",url:'./1_1.html?typeid=33',active:false,parent_id:15 ,children:[]},
                            { "value":34, "label":"协会动态" ,url:'./1_1.html?typeid=34',active:false,parent_id:15,children:[]}
                        ]
                    },
                    { "value":16, "label":"通知公告",  "children":[],url:'./list.html?typeid=16',active:false },
                    { "value":17, "label":"工作动态", "children":[] ,url:'./list.html?typeid=17',active:false},
                    { "value":18, "label":"政策法规", "children":[] ,url:'./list.html?typeid=18',active:false},
                    {
                        "value":19,
                        "label":"项目信息",
                        url:'./5_1.html?typeid=36',
                        active:false,
                        has_subnav:false,
                        "children":[
                            {"value":35,"label":"项目信息",url:'./5_1.html?typeid=36',active:false ,parent_id:19,children:[]},
                            {"value":36, "label":"招标公告",url:'./5_1.html?typeid=36',active:false,parent_id:19,children:[]},
                            {"value":35,"label":"资格预审公告",url:'./5_1.html?typeid=35',active:false ,parent_id:19,children:[]},
                            {"value":37,"label":"中标候选人公示",url:'./5_1.html?typeid=37',active:false,parent_id:19,children:[]},
                            {"value":38, "label":"中标结果公示",url:'./5_1.html?typeid=38',active:false,parent_id:19,children:[]}
                        ]
                    },
                    { "value":20, "label":"行业资讯", "children":[] ,url:'./list.html?typeid=20',active:false},
                    {
                        "value":21,
                        "label":"会员之窗",
                        url:'./7_1.html?typeid=39',
                        active:false,
                        has_subnav:false,
                        "children":[
                            {"value":39,"label":"会员之窗",url:'./7_1.html?typeid=39',active:false,parent_id:21,children:[]},
                            {"value":39,"label":"入会申请",url:'./7_1.html?typeid=39',active:false,parent_id:21,children:[]},
                             {"value":44,"label":"会员名单",url:'./7_1.html?typeid=44',active:false,parent_id:21,children:[]},
                            {"value":40,"label":"会员证书",url:'./7_1.html?typeid=40',active:false,parent_id:21,children:[]},
                            {"value":41, "label":"建言献策",url:'./7_1.html?typeid=41',active:false ,parent_id:21,children:[]},
                            {"value":42,"label":"举报投诉",url:'./7_1.html?typeid=42',active:false,parent_id:21,children:[]},
                            {"value":43,"label":"意见反馈",url:'./7_1.html?typeid=43',active:false,parent_id:21,children:[]}
                        ]
                    },
                    
                    { "value":22, "label":"学习探讨", "children":[] ,url:'./list.html?typeid=22',active:false},
                    { "value":23, "label":"警钟长鸣", "children":[] ,url:'./list.html?typeid=23',active:false},
                ]
                ,typeid : util.parse_query().typeid
            }
        }
        ,mounted : async function(){
            this.index_from_uri();
        }
        ,methods:{
            active(e,el,index){
                location.href = el.url;
                 var sub_menu = JSON.stringify(el.children);
                localStorage.setItem('top_menu_id',el.value);
                localStorage.setItem('sub_menu',sub_menu);
            }
            ,index_from_uri(){
                var index = find(this.nav,this.typeid);
                this.nav[index].active = true;
                this.$forceUpdate();
                
            }
            ,active_subnav(e,el,index){
                this.nav[index].has_subnav = true;
            }
            ,unactive_subnav(e,el,index){
                this.nav[index].has_subnav = false;
            }
            ,clear_active(){
                for(var i=0; i<this.nav.length; i++){
                    var nav = this.nav[i];
                    nav.active = false;
                }
            }
        }
    }
</script>